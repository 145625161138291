<template>
  <transition v-if="!loading" name="fade">
    <div id="wrapper">
        <sidebar :page="'Manage Users'" :sidebar_data=sidebar_data></sidebar>
        <div class="body">
            <div class="backdrop" v-on:click="close()"></div>
              
            <admin-header :title="'Manage Site'" :tagline="'All your controls, in one place.'" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url :user=user style="margin-bottom: 3rem;"></admin-header>

            <div class="container mb-4">
              <div class="content-wrapper">
                <div class="content mx-auto">

                  <!--  List 1 -->
                  <auto-list v-if="url != null" v-model:all_screens=all_screens v-model:screen="screen" :title=title v-model:url="url" v-model:list_data="list_data"></auto-list>

                </div>                                          
              </div>
            </div>
            
            <main-footer></main-footer>
        </div>

        <add-user></add-user>
    </div>
  </transition>
</template>

<script>
import MainFooter from '../../components/Footer'
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import AutoList from '../../components/AutoList'
import AddUser from '../../components/Modals/AddUser'
import SidebarData from '../../assets/json/Admin/SidebarData'

export default {
  name: 'AdminUsers',
  components: {
    AdminHeader,
    MainFooter,
    Sidebar,
    AutoList,
    AddUser
  },
  data() {
    return {
      url: 'api/users',
      title: {status: true, title: 'Your Data', actions: [
        {name: 'Add User', type: 'modal', action_url: 'add-user'}
      ]},
      all_screens: [
        {title: 'Active Users', screen: 1, url: 'api/active-users', actions: [
          {name: 'Edit', type: 'modal', action_url: 'edit-user'},
          {name: 'Deactivate', type: 'api', action_url: {type: 'put', url:['api/users/', '?deactivated=true'], message: {success: 'Success!', error: 'Error!'}}},
          {name: 'Make Admin', type: 'api', action_url: {type: 'put', url:['api/users/', '?admin=true'], message: {success: 'Success!', error: 'Error!'}}},
          {name: 'Delete', type: 'api', action_url: {type: 'delete', url:'api/users/', message: {success: 'Success!', error: 'Error!'}}},
        ]},
        {title: 'Deactivated Users', screen: 1, url: 'api/deactivated-users', actions: [
          {name: 'Edit', type: 'modal', action_url: 'users'},
          {name: 'Activate', type: 'api', action_url: {type: 'put', url:['api/users/', '?deactivated=false'], message: {success: 'Success!', error: 'Error!'}}},
          {name: 'Delete', type: 'api', action_url: {type: 'delete', url:'api/users/', message: {success: 'Success!', error: 'Error!'}}},
        ]},
        {title: 'Administrators', screen: 1, url: 'api/admin-users', actions: [
          {name: 'Remove Admin', type: 'api', action_url: {type: 'put', url:['api/users/', '?admin=false'], message: {success: 'Success!', error: 'Error!'}}},
          {name: 'Delete', type: 'api', action_url: {type: 'delete', url:'api/users/', message: {success: 'Success!', error: 'Error!'}}},
        ]},
        {title: 'Drivers', screen: 1, url: 'api/driver', actions: [
          {name: 'View Details', type: 'modal', action_url: 'view-driver-details'},
        ]},
        {title: 'Doctors', screen: 1, url: 'api/doctor', actions: [
          {name: 'View Details', type: 'modal', action_url: 'view-doctor-details'},
        ]}                                    
      ],   
      sidebar_data: SidebarData,
      screen: 1,
      list_data: []         
    }
  },
  watch: {
    user: function() {
      this.ifNotAdmin()
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    loading () {
      return this.$store.state.loading
    }       
  },    
  mounted() {
    this.$store.dispatch("getUser")
  },
  methods: {
    ifNotAdmin() {
      if( !this.user.admin) {
        this.$router.push({name: 'Home'});
      }
    }
  }
}
</script>
