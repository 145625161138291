<template>
    <!-- Modal -->
    <div class="modal fade" id="add-user" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Add User</h5>
                        <p class="text-muted">Add User.</p>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>

                            <div class="form-row mt-1">
                                <label for="names" class="fw-bold mb-2">Enter your Names</label>  
                                <div class="input-group">
                                    <span class="input-group-text border-right-0">
                                        <font-awesome-icon id="eye" :icon="['fa', 'user']" />
                                    </span>

                                    <input id="names" type="text" class="form-control no-border" v-model="data.user.name" placeholder="Enter your Names" required> 
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                                    <label for="email" class="fw-bold mb-2">Enter your Email</label>
                                    <div class="input-group">
                                        <span class="input-group-text border-right-0">
                                            <font-awesome-icon id="eye" :icon="['fa', 'envelope']" />
                                        </span>
                                        
                                        <input id="email" type="email" class="form-control no-border" v-model="data.user.email" placeholder="Enter your Email" required>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                                    <label for="phone_number" class="fw-bold mb-2">Enter your Phone Number</label>
                                    <div class="input-group">
                                        <span class="input-group-text border-right-0">
                                            <font-awesome-icon id="eye" :icon="['fa', 'phone-alt']" />
                                        </span>
                                        
                                        <input id="phone_number" type="number" class="form-control no-border" v-model="data.user.phone_number" placeholder="Enter your Phone Number" required>
                                    </div>
                                </div>   
                            </div>                    

                            <form action="#" @submit.prevent="handleSubmit()">
                                <button class="btn bg-orange text-uppercase text-white mt-4 w-100">
                                    <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';

    .form-select {
        border: 0;
        border-bottom: 1px solid #eee;
    }
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'AddUser',
        props: {
            supplier_id: {
                required: true,
                type: Number
            }
        },
        components: {
            Status
        },
        data() {
            return {
                screen: 0,
                data: {
                    user: {
                        name: null,
                        email: null,
                        phone_number: null,
                        password: null,
                        cpassword: null,
                    }
                },
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true

                this.axios.post('api/users', this.data.user).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'User Added!'
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.data

                    console.log(error);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
        }
    }
</script>